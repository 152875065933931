import { replaceTokens, getQueryVariableArrays } from './functions'

const knownParams = ['transaction_id', 'aff_id', 'aff_sub', 'aff_sub2']
const params = getQueryVariableArrays()

const urlList = {
  1: {
    signup:
      'https://t.frtyo.com/3h56tce3gg?offer_id=2500&transaction_id={transaction_id}&aff_id={aff_id}'
  },
  2: {
    signup: 'https://a.vfghd.com/click'
  }
}

const returnUrl = () => {
  const { url, ukey } = params
  let link = urlList[url] ? urlList[url] : urlList[1]
  link = ukey && link[ukey] ? link[ukey] : link.signup

  if (params.transaction_id) {
    return replaceTokens(link, params, knownParams)
  }

  return false
}

export default returnUrl
