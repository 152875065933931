import React, { useEffect } from 'react'
import returnUrl from '../config'

const IndexPage = () => {
  useEffect(() => {
    const url = returnUrl()
    if (url) {
      window.location.replace(url)
    }
  }, [])

  return <></>
}

export default IndexPage
