export const getQueryVariableArrays = () => {
  if (typeof window !== 'undefined') {
    const params = window.location.search.substring(1).split('&')
    const finalVars = {}

    params.map(param => {
      const pair = param.split('=')
      finalVars[pair[0]] = pair[1]
    })

    return finalVars
  }
}

export const replaceTokens = (url, query, knownParams) => {
  let newUrl = url

  knownParams.map(key => {
    const reg = new RegExp(`{${key}}`, 'g')
    newUrl = newUrl.replace(reg, query[key] ? query[key] : '')
  })

  return newUrl
}
